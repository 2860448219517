exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-technologies-js": () => import("./../../../src/pages/about-us/technologies.js" /* webpackChunkName: "component---src-pages-about-us-technologies-js" */),
  "component---src-pages-about-us-why-agile-js": () => import("./../../../src/pages/about-us/why-agile.js" /* webpackChunkName: "component---src-pages-about-us-why-agile-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/AGB.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-career-vacancies-project-manager-js": () => import("./../../../src/pages/career/vacancies/project-manager.js" /* webpackChunkName: "component---src-pages-career-vacancies-project-manager-js" */),
  "component---src-pages-career-vacancies-senior-full-stack-developer-php-js": () => import("./../../../src/pages/career/vacancies/senior-full-stack-developer-php.js" /* webpackChunkName: "component---src-pages-career-vacancies-senior-full-stack-developer-php-js" */),
  "component---src-pages-career-vacancies-senior-software-architect-js": () => import("./../../../src/pages/career/vacancies/senior-software-architect.js" /* webpackChunkName: "component---src-pages-career-vacancies-senior-software-architect-js" */),
  "component---src-pages-career-vacancies-technical-project-manager-js": () => import("./../../../src/pages/career/vacancies/technical-project-manager.js" /* webpackChunkName: "component---src-pages-career-vacancies-technical-project-manager-js" */),
  "component---src-pages-case-study-aldiana-index-js": () => import("./../../../src/pages/case-study/aldiana/index.js" /* webpackChunkName: "component---src-pages-case-study-aldiana-index-js" */),
  "component---src-pages-case-study-arz-index-js": () => import("./../../../src/pages/case-study/arz/index.js" /* webpackChunkName: "component---src-pages-case-study-arz-index-js" */),
  "component---src-pages-case-study-bedfinder-index-js": () => import("./../../../src/pages/case-study/bedfinder/index.js" /* webpackChunkName: "component---src-pages-case-study-bedfinder-index-js" */),
  "component---src-pages-case-study-case-study-modern-test-index-js": () => import("./../../../src/pages/case-study/case-study-modern-test/index.js" /* webpackChunkName: "component---src-pages-case-study-case-study-modern-test-index-js" */),
  "component---src-pages-case-study-dertouristik-index-js": () => import("./../../../src/pages/case-study/dertouristik/index.js" /* webpackChunkName: "component---src-pages-case-study-dertouristik-index-js" */),
  "component---src-pages-case-study-etravel-index-js": () => import("./../../../src/pages/case-study/etravel/index.js" /* webpackChunkName: "component---src-pages-case-study-etravel-index-js" */),
  "component---src-pages-case-study-hlx-touristik-index-js": () => import("./../../../src/pages/case-study/hlx-touristik/index.js" /* webpackChunkName: "component---src-pages-case-study-hlx-touristik-index-js" */),
  "component---src-pages-case-study-index-js": () => import("./../../../src/pages/case-study/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-case-study-plattenplaner-index-js": () => import("./../../../src/pages/case-study/plattenplaner/index.js" /* webpackChunkName: "component---src-pages-case-study-plattenplaner-index-js" */),
  "component---src-pages-case-study-s-reisewelt-index-js": () => import("./../../../src/pages/case-study/s-reisewelt/index.js" /* webpackChunkName: "component---src-pages-case-study-s-reisewelt-index-js" */),
  "component---src-pages-case-study-weekendcom-index-js": () => import("./../../../src/pages/case-study/weekendcom/index.js" /* webpackChunkName: "component---src-pages-case-study-weekendcom-index-js" */),
  "component---src-pages-case-study-y-travel-dev-index-js": () => import("./../../../src/pages/case-study/y-travel-dev/index.js" /* webpackChunkName: "component---src-pages-case-study-y-travel-dev-index-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contactConfirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-ibe-js": () => import("./../../../src/pages/contact/ibe.js" /* webpackChunkName: "component---src-pages-contact-ibe-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-fair-company-js": () => import("./../../../src/pages/fair-company.js" /* webpackChunkName: "component---src-pages-fair-company-js" */),
  "component---src-pages-fair-js": () => import("./../../../src/pages/fair.js" /* webpackChunkName: "component---src-pages-fair-js" */),
  "component---src-pages-fair-showcase-js": () => import("./../../../src/pages/fair-showcase.js" /* webpackChunkName: "component---src-pages-fair-showcase-js" */),
  "component---src-pages-fair-solutions-js": () => import("./../../../src/pages/fair-solutions.js" /* webpackChunkName: "component---src-pages-fair-solutions-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-itb-2020-js": () => import("./../../../src/pages/itb2020.js" /* webpackChunkName: "component---src-pages-itb-2020-js" */),
  "component---src-pages-itb-video-stand-js": () => import("./../../../src/pages/itb-video-stand.js" /* webpackChunkName: "component---src-pages-itb-video-stand-js" */),
  "component---src-pages-landingpages-e-commerce-index-js": () => import("./../../../src/pages/landingpages/e-commerce/index.js" /* webpackChunkName: "component---src-pages-landingpages-e-commerce-index-js" */),
  "component---src-pages-landingpages-headless-cms-index-js": () => import("./../../../src/pages/landingpages/headless-cms/index.js" /* webpackChunkName: "component---src-pages-landingpages-headless-cms-index-js" */),
  "component---src-pages-landingpages-ibe-index-js": () => import("./../../../src/pages/landingpages/ibe/index.js" /* webpackChunkName: "component---src-pages-landingpages-ibe-index-js" */),
  "component---src-pages-landingpages-software-development-b-index-js": () => import("./../../../src/pages/landingpages/software-development_b/index.js" /* webpackChunkName: "component---src-pages-landingpages-software-development-b-index-js" */),
  "component---src-pages-landingpages-software-development-index-js": () => import("./../../../src/pages/landingpages/software-development/index.js" /* webpackChunkName: "component---src-pages-landingpages-software-development-index-js" */),
  "component---src-pages-landingpages-ux-design-index-js": () => import("./../../../src/pages/landingpages/ux-design/index.js" /* webpackChunkName: "component---src-pages-landingpages-ux-design-index-js" */),
  "component---src-pages-news-adac-travel-sales-relaunched-with-freshcells-js": () => import("./../../../src/pages/news/adac-travel-sales-relaunched-with-freshcells.js" /* webpackChunkName: "component---src-pages-news-adac-travel-sales-relaunched-with-freshcells-js" */),
  "component---src-pages-news-agentur-freshcells-launcht-online-reiseportal-fur-fischer-gruppe-js": () => import("./../../../src/pages/news/agentur-freshcells-launcht-online-reiseportal-fur-fischer-gruppe.js" /* webpackChunkName: "component---src-pages-news-agentur-freshcells-launcht-online-reiseportal-fur-fischer-gruppe-js" */),
  "component---src-pages-news-aldiana-tech-lead-js": () => import("./../../../src/pages/news/aldiana-tech-lead.js" /* webpackChunkName: "component---src-pages-news-aldiana-tech-lead-js" */),
  "component---src-pages-news-contentstack-certified-catalyst-js": () => import("./../../../src/pages/news/contentstack-certified-catalyst.js" /* webpackChunkName: "component---src-pages-news-contentstack-certified-catalyst-js" */),
  "component---src-pages-news-eurowings-live-js": () => import("./../../../src/pages/news/eurowings-live.js" /* webpackChunkName: "component---src-pages-news-eurowings-live-js" */),
  "component---src-pages-news-festo-rollt-zentrale-produktdaten-schnittstelle-von-freshcells-aus-js": () => import("./../../../src/pages/news/festo-rollt-zentrale-produktdaten-schnittstelle-von-freshcells-aus.js" /* webpackChunkName: "component---src-pages-news-festo-rollt-zentrale-produktdaten-schnittstelle-von-freshcells-aus-js" */),
  "component---src-pages-news-frescells-at-itb-2023-js": () => import("./../../../src/pages/news/frescells-at-itb-2023.js" /* webpackChunkName: "component---src-pages-news-frescells-at-itb-2023-js" */),
  "component---src-pages-news-freshcells-announces-integration-of-chatgpt-into-freshms-js": () => import("./../../../src/pages/news/freshcells-announces-integration-of-chatgpt-into-freshms.js" /* webpackChunkName: "component---src-pages-news-freshcells-announces-integration-of-chatgpt-into-freshms-js" */),
  "component---src-pages-news-freshcells-appoints-marvin-strenger-as-chief-operating-officer-js": () => import("./../../../src/pages/news/freshcells-appoints-marvin-strenger-as-chief-operating-officer.js" /* webpackChunkName: "component---src-pages-news-freshcells-appoints-marvin-strenger-as-chief-operating-officer-js" */),
  "component---src-pages-news-freshcells-builds-highly-modern-foundation-for-website-of-next-kraftwerke-js": () => import("./../../../src/pages/news/freshcells-builds-highly-modern-foundation-for-website-of-next-kraftwerke.js" /* webpackChunkName: "component---src-pages-news-freshcells-builds-highly-modern-foundation-for-website-of-next-kraftwerke-js" */),
  "component---src-pages-news-freshcells-presents-new-interactive-modules-at-itb-2023-js": () => import("./../../../src/pages/news/freshcells-presents-new-interactive-modules-at-itb-2023.js" /* webpackChunkName: "component---src-pages-news-freshcells-presents-new-interactive-modules-at-itb-2023-js" */),
  "component---src-pages-news-freshcells-relaunched-die-reise-vertriebsplattform-von-s-markt-und-mehrwert-js": () => import("./../../../src/pages/news/freshcells-relaunched-die-reise-vertriebsplattform-von-s-markt-und-mehrwert.js" /* webpackChunkName: "component---src-pages-news-freshcells-relaunched-die-reise-vertriebsplattform-von-s-markt-und-mehrwert-js" */),
  "component---src-pages-news-freshcells-technologie-ermoglicht-alltours-den-schnellen-umstieg-zu-peakwork-js": () => import("./../../../src/pages/news/freshcells-technologie-ermöglicht-alltours-den-schnellen-umstieg-zu-peakwork.js" /* webpackChunkName: "component---src-pages-news-freshcells-technologie-ermoglicht-alltours-den-schnellen-umstieg-zu-peakwork-js" */),
  "component---src-pages-news-freshcells-zeigt-auf-der-itb-2020-die-hightech-ibe-zum-einsteigertarif-js": () => import("./../../../src/pages/news/freshcells-zeigt-auf-der-itb-2020-die-hightech-ibe-zum-einsteigertarif.js" /* webpackChunkName: "component---src-pages-news-freshcells-zeigt-auf-der-itb-2020-die-hightech-ibe-zum-einsteigertarif-js" */),
  "component---src-pages-news-legacy-risiko-und-chance-zugleich-in-zeiten-der-digitalen-transformation-js": () => import("./../../../src/pages/news/legacy-risiko-und-chance-zugleich-in-zeiten-der-digitalen-transformation.js" /* webpackChunkName: "component---src-pages-news-legacy-risiko-und-chance-zugleich-in-zeiten-der-digitalen-transformation-js" */),
  "component---src-pages-news-mefa-js": () => import("./../../../src/pages/news/mefa.js" /* webpackChunkName: "component---src-pages-news-mefa-js" */),
  "component---src-pages-news-relaunch-mitsubishi-electric-factory-automation-emea-js": () => import("./../../../src/pages/news/relaunch-mitsubishi-electric-factory-automation-emea.js" /* webpackChunkName: "component---src-pages-news-relaunch-mitsubishi-electric-factory-automation-emea-js" */),
  "component---src-pages-news-schweizer-reiseanbieter-universal-flugreisen-ag-startet-technologie-partnerschaft-mit-freshcells-systems-engineering-js": () => import("./../../../src/pages/news/schweizer-reiseanbieter-universal-flugreisen-ag-startet-technologie-partnerschaft-mit-freshcells-systems-engineering.js" /* webpackChunkName: "component---src-pages-news-schweizer-reiseanbieter-universal-flugreisen-ag-startet-technologie-partnerschaft-mit-freshcells-systems-engineering-js" */),
  "component---src-pages-news-stadt-radevormwald-setzt-auf-it-consulting-von-freshcells-js": () => import("./../../../src/pages/news/stadt-radevormwald-setzt-auf-it-consulting-von-freshcells.js" /* webpackChunkName: "component---src-pages-news-stadt-radevormwald-setzt-auf-it-consulting-von-freshcells-js" */),
  "component---src-pages-news-travelsandbox-from-freshcells-as-driver-for-hlx-touristik-business-model-js": () => import("./../../../src/pages/news/travelsandbox-from-freshcells-as-driver-for-hlx-touristik-business-model.js" /* webpackChunkName: "component---src-pages-news-travelsandbox-from-freshcells-as-driver-for-hlx-touristik-business-model-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-privacy-policy-applicants-js": () => import("./../../../src/pages/privacy-policy-applicants.js" /* webpackChunkName: "component---src-pages-privacy-policy-applicants-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-freshms-js": () => import("./../../../src/pages/products/freshms.js" /* webpackChunkName: "component---src-pages-products-freshms-js" */),
  "component---src-pages-products-fusion-js": () => import("./../../../src/pages/products/fusion.js" /* webpackChunkName: "component---src-pages-products-fusion-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-mightyc-js": () => import("./../../../src/pages/products/mightyc.js" /* webpackChunkName: "component---src-pages-products-mightyc-js" */),
  "component---src-pages-products-quickstart-js": () => import("./../../../src/pages/products/quickstart.js" /* webpackChunkName: "component---src-pages-products-quickstart-js" */),
  "component---src-pages-products-travelsandbox-js": () => import("./../../../src/pages/products/travelsandbox.js" /* webpackChunkName: "component---src-pages-products-travelsandbox-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-services-app-development-js": () => import("./../../../src/pages/services/app-development.js" /* webpackChunkName: "component---src-pages-services-app-development-js" */),
  "component---src-pages-services-concept-strategy-js": () => import("./../../../src/pages/services/concept-strategy.js" /* webpackChunkName: "component---src-pages-services-concept-strategy-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-headless-cms-integration-contentstack-index-js": () => import("./../../../src/pages/services/headless-cms-integration/contentstack/index.js" /* webpackChunkName: "component---src-pages-services-headless-cms-integration-contentstack-index-js" */),
  "component---src-pages-services-headless-cms-integration-index-js": () => import("./../../../src/pages/services/headless-cms-integration/index.js" /* webpackChunkName: "component---src-pages-services-headless-cms-integration-index-js" */),
  "component---src-pages-services-headless-cms-integration-strapi-index-js": () => import("./../../../src/pages/services/headless-cms-integration/strapi/index.js" /* webpackChunkName: "component---src-pages-services-headless-cms-integration-strapi-index-js" */),
  "component---src-pages-services-it-consulting-js": () => import("./../../../src/pages/services/it-consulting.js" /* webpackChunkName: "component---src-pages-services-it-consulting-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-project-management-js": () => import("./../../../src/pages/services/project-management.js" /* webpackChunkName: "component---src-pages-services-project-management-js" */),
  "component---src-pages-services-system-engineering-js": () => import("./../../../src/pages/services/system-engineering.js" /* webpackChunkName: "component---src-pages-services-system-engineering-js" */),
  "component---src-pages-services-system-integration-js": () => import("./../../../src/pages/services/system-integration.js" /* webpackChunkName: "component---src-pages-services-system-integration-js" */),
  "component---src-pages-services-ui-ux-design-js": () => import("./../../../src/pages/services/ui-ux-design.js" /* webpackChunkName: "component---src-pages-services-ui-ux-design-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-it-system-modernization-index-js": () => import("./../../../src/pages/solutions/it-system-modernization/index.js" /* webpackChunkName: "component---src-pages-solutions-it-system-modernization-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-xmas-index-js": () => import("./../../../src/pages/xmas/index.js" /* webpackChunkName: "component---src-pages-xmas-index-js" */)
}

